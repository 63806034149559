import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {withTranslation} from 'react-i18next'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import NumberFormat from 'react-number-format'
import {HashLink as Link} from 'react-router-hash-link'
import Collapse from '@mui/material/Collapse'
import Alert from '@mui/material/Alert'
import useAuth from '../../hooks/useAuth'
import BlockUi from 'react-block-ui'
import AddCardForm from "../AddCardForm";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {Modal} from 'react-bootstrap'
import Switch from '@mui/material/Switch'

const MERCHANT_PG_IDENTIFIER = process.env.GENIE_MERCHANT_PG_IDENTIFIER
const CURRENCY = process.env.GENIE_CURRENCY
const sPAYMENT_METHOD = process.env.GENIE_PAYMENT_METHOD
const SUCCESS_URL = process.env.GENIE_SUCCESS_URL
const ERROR_URL = process.env.GENIE_ERROR_URL
const STORE_NAME = process.env.GENIE_STORE_NAME
const GENIE_URL = process.env.GENIE_URL
const MERCHANT_NAME = process.env.MERCHANT_NAME
const MINIMUM_TOPUP_AMOUNT = process.env.MINIMUM_TOPUP_AMOUNT
const WEBX_PAY_SECRET_KEY = process.env.WEBX_PAY_SECRET_KEY

const TopupForm = (props: any) => {
    const {
        t,
        open,
        handleClose,
        total,
        tickets,
        handleBuyTickets,
        subscriptionRequest,
        handlErrors,
        lotteryName,
        readOnly,
    } = props

    // console.log("total", total)
    const navigate = useHistory()
    const location = useLocation()
    const from = location.state?.from?.pathname || '/'
    const {auth} = useAuth()

    const axiosPrivate = useAxiosPrivate()
    const [amount, setAmount] = useState(MINIMUM_TOPUP_AMOUNT)
    const [order, setOrder] = useState({})
    const [token, setToken] = useState({})
    const [user, setUser] = useState({})
    const [errMsg, setErrMsg] = useState({})
    const [save, setSave] = useState(false)
    const [saveCard, setSaveCard] = useState('N')
    const [cardList, setCardList] = useState([])
    const [card, setCard] = useState()
    const [recPayment, setRecPayment] = useState([])
    const [blocking, setBlocking] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [error, setError] = useState(false)
    const [checked, setChecked] = useState(false)
    const [tokenization, setTokenization] = useState(1)
    const [success, setSuccess] = useState(false)
    const [paymentGateway, setPaymentGateway] = useState('webxpay')
    const [paymentMethod, setPaymentMethod] = useState("card_payment")
    const [selectedCardId, setSelectedCardId] = useState(null)
    const [extraChargeAlert, setExtraChargeAlert] = useState(false)
    const [addNewCard, setAddNewCard] = useState(false)

    useEffect(() => {
        if (Object.keys(tickets).length > 0) {
            if (cardList.length > 0) {
                if (paymentGateway === 'webxpay') {
                    createOrder()
                } else {
                    payOrderEx()
                }
            } else {
                createOrder()
            }
        }
    }, [tickets])

    useEffect(() => {
        if (paymentMethod === 'mobile_payment') {
            console.log(paymentMethod)
            setExtraChargeAlert(false)
            setSelectedCardId(null)
        } else {
            setAmount(parseFloat(Math.ceil(total)).toFixed(2))
            setExtraChargeAlert(true)
        }
    }, [paymentMethod]);

    useEffect(() => {
        if (parseFloat(amount) < 2000.00 && paymentMethod === 'card_payment') {
            setExtraChargeAlert(true)
        } else {
            setExtraChargeAlert(false)
        }
    }, [amount]);

    useEffect(() => {
        if (Object.keys(subscriptionRequest).length > 0) {
            if (cardList.length > 0) {
                if (paymentGateway === 'webxpay') {
                    createOrder()
                } else {
                    payOrderEx()
                }
            } else {
                createOrder()
            }
        }
    }, [subscriptionRequest])

    useEffect(() => {
        if (Object.keys(order).length > 0) {
            document.getElementById('transForm').submit()
        }
    }, [order])

    useEffect(() => {
        const userx = JSON.parse(localStorage.getItem('user'))
        const atkn = localStorage.getItem('atkn')

        setErrMsg('')
        setUser(userx)
        setToken(atkn)
        setRecPayment([])
        setCardList([])
        setSuccess(false)
        setErrorMsg('')
        // console.log(total);
        if (parseFloat(total).toFixed(2) > parseInt(MINIMUM_TOPUP_AMOUNT)) {
            console.log('total', total)
            console.log('Math.ceil(total)', Math.ceil(total))
            if (Math.ceil(total) < 2000) {
                setAmount(parseFloat(Math.ceil(total)).toFixed(2))
            } else {
                setAmount(parseFloat(Math.ceil(total)).toFixed(2))
            }
        }

        if (parseFloat(total).toFixed(2) <= parseInt(MINIMUM_TOPUP_AMOUNT)) {
            if (Math.ceil(total) < 2000) {
                setAmount(parseFloat(parseInt(MINIMUM_TOPUP_AMOUNT)).toFixed(2))
            } else {
                setAmount(parseInt(MINIMUM_TOPUP_AMOUNT))
            }
        }

        const controller = new AbortController()

        const fetchCardList = async () => {
            try {
                const response = await axiosPrivate.get('paymentCard/list', {
                    signal: controller.signal,
                })

                const cardx =
                    paymentGateway === 'webxpay'
                        ? response?.data?.webxpay || []
                        : response?.data?.genie || []

                setCardList(cardx)

                const defaultCard = cardx.find((card) => card.is_default === 1);
                if (selectedCardId !== 0) {
                    if (defaultCard) {
                        setSelectedCardId(defaultCard.id);
                    } else {
                        if (cardx.length > 0) {
                            setSelectedCardId(cardx[0].id)
                        } else {
                            setSelectedCardId(null)
                        }
                    }
                }

                console.log('cardx', cardx)
                if (cardx) {
                    setCard(cardx[0]['id'])
                }
            } catch (err) {
                if (!err?.response) {
                    setErrMsg(t('No Server Response'))
                } else if (err.response?.status === 422) {
                    setErrMsg(err.response.data?.errors[0])
                } else {
                    setErrMsg(err.response.data?.errors?.message)
                }
            }
        }

        if (paymentMethod !== 'mobile_payment') {
            fetchCardList()
        } else {
            setAmount(parseFloat(Math.ceil(total)).toFixed(2))
        }
    }, [open, total, paymentGateway, paymentMethod])

    const handleAmountChange = (e) => {
        const value = e.target.value
        if (/^\d*$/.test(value)) {
            setAmount(value)
        } else {
            ;<h6>Please enter an integer value.</h6>
        }
    }
    const createOrderEx = (e: any) => {
        e.preventDefault()
        if (cardList.length > 0) {
            if (paymentGateway === 'webxpay') {
                createOrder()
            } else {
                payOrderEx()
            }
        } else {
            createOrder()
        }
    }

    const handlePaymentTypeOnChange = (value: any) => {
        setPaymentMethod(value)
    }

    const payOrderEx = async () => {
        try {
            if (
                amount == undefined ||
                parseFloat(amount).toFixed(2) < parseInt(MINIMUM_TOPUP_AMOUNT)
            ) {
                setErrorMsg(
                    'Entered Topup Amount Error.The minimum topup amount is : ' +
                    MINIMUM_TOPUP_AMOUNT
                )
                setError(true)
                setBlocking(false)
                return
            }

            if (card == undefined) {
                setErrorMsg(t('Please select a credit card.'))
                setError(true)
                setBlocking(false)
                return
            }

            setBlocking(true)
            const response = await axiosPrivate.post('/payby/genieBusiness/card', {
                card_id: card,
                amount: amount,
                tickets: tickets,
                subscription: subscriptionRequest,
            })
            console.log('responsed', response)

            const url = response?.data?.transaction['vendorUrl']
            window.location.href = url
            // const transaction = response?.data?.transaction
            // const ticketdata = response?.data?.ticket_data
            // const subscriptiondata = response?.data?.subscription_data

            // if (Object.keys(tickets).length > 0) {
            //     handleBuyTickets(ticketdata, transaction)
            // } else if (Object.keys(subscriptionRequest).length > 0) {
            //     handleBuyTickets(subscriptiondata, transaction)
            // } else {
            //     setSuccess(true)
            //     setRecPayment(transaction)
            // }
            setBlocking(false)
        } catch (err) {
            setBlocking(false)
            // handleBuyTickets({}, {})
            if (!err?.response) {
                handleBuyTickets({}, {})
                if (handlErrors) handlErrors('No Server Response')
                else setErrMsg(t('No Server Response'))
            } else if (err.response?.status === 422) {
                if (Array.isArray(err.response.data?.errors)) {
                    if (handlErrors) handlErrors(err.response.data?.errors[0])
                    else setErrMsg(err.response.data?.errors[0])
                } else if (handlErrors) handlErrors(err.response.data?.errors)
                else setErrMsg(err.response.data?.errors)
            } else {
                if (handlErrors) handlErrors(err.response.data?.errors?.message)
                else setErrMsg(err.response.data?.errors?.message)
            }
        }
    }

    const createOrderX = async (e: any) => {
        e.preventDefault()
        createOrder()
    }

    const createOrder = async () => {
        if (selectedCardId === 0) {
            setAddNewCard(true)
            return;
        }

        debugger;

        try {
            setBlocking(true)
            if (
                amount == undefined ||
                parseFloat(amount).toFixed(2) < parseInt(MINIMUM_TOPUP_AMOUNT)
            ) {
                setErrorMsg(
                    'Entered Topup Amount Error.The minimum topup amount is : ' +
                    MINIMUM_TOPUP_AMOUNT
                )
                setBlocking(false)
                setError(true)
                return
            }
            console.log('genie Topup ammount', amount)

            console.log('pt1', selectedCardId)


            const data = {
                payment_type: paymentGateway,
                // amount: parseFloat(amount).toFixed(2),
                card_id: selectedCardId,
                payment_method: paymentMethod,
                checked: checked,
                save_card: saveCard,
                amount: amount,
                tokenization: tokenization,
                lottery_name: lotteryName,
                tickets_request:
                    Object.keys(tickets).length > 0 ? JSON.stringify(tickets) : null,
                subscription_request:
                    Object.keys(subscriptionRequest).length > 0
                        ? JSON.stringify(subscriptionRequest)
                        : null,
            }

            console.log(data)

            const response = await axiosPrivate.post(
                'user/transaction/add/' + user.id, data
            )

            if (response?.data?.transaction['payment_type'] === 'genie') {
                const url = response?.data?.transaction['decodedSecretCode']['url']
                window.location.href = url
            }

            console.log(response)
            debugger;
            // const orderx = response?.data?.transaction
            /*     setOrder(orderx) */

            if (response?.data?.transaction['payment_type'] === 'webxpay') {
                const data = response?.data?.transaction

                console.log('pt1', data)
                console.log('pt1', data['payment'])

                const form = document.createElement('form')
                form.action = data['url']
                form.method = 'post'
                form.style.display = 'none'

                const inputs = [
                    {name: 'first_name', value: user.fname},
                    {name: 'last_name', value: user.lname},
                    {name: 'email', value: user.email},
                    {name: 'contact_number', value: user.mobile},
                    {name: 'process_currency', value: 'LKR'},
                    {name: 'enc_method', value: 'JCs3J+6oSz4V0LgE0zi/Bg=='},
                    {name: 'secret_key', value: WEBX_PAY_SECRET_KEY},
                    {name: 'custom_fields', value: data['custom_field']},
                    {name: 'payment', value: data['payment']},
                    {name: 'multiple_payment_gateway_ids', value: data['gateway_ids']},
                ]

                inputs.forEach((input) => {
                    const inputElement = document.createElement('input')
                    inputElement.type = 'hidden'
                    inputElement.name = input.name
                    inputElement.value = input.value
                    form.appendChild(inputElement)
                })
                document.body.appendChild(form)
                form.submit()
            } else {
                const transaction = response?.data?.transaction
                const ticketdata = response?.data?.ticket_data
                const subscriptiondata = response?.data?.subscription_data

                if (Object.keys(tickets).length > 0) {
                    handleBuyTickets(ticketdata, transaction)
                } else if (Object.keys(subscriptionRequest).length > 0) {
                    handleBuyTickets(subscriptiondata, transaction)
                } else {
                    setSuccess(true)
                    setRecPayment(transaction)
                }
            }
        } catch (err) {
            setBlocking(false)
            if (!err?.response) {
                setErrMsg(t('No Server Response'))
            } else if (err.response?.status === 422) {
                if (Array.isArray(err.response.data?.errors))
                    setErrMsg(err.response.data?.errors[0])
                else setErrMsg(err.response.data?.errors)
            } else {
                setErrMsg(err.response.data?.errors?.message)
            }
        }
    }

    const handleCardSaving = (e: any) => {
        const isChecked = e.target.checked
        if (isChecked) {
            setChecked(true)
            setSaveCard('Y')
        } else {
            setChecked(false)
            setSaveCard('N')
        }
        setSave(isChecked)
    }

    // @ts-ignore
    return (
        <>
            <BlockUi tag="div" blocking={blocking}>
                <div className="row popup-section topup-section">
                    {/* <img
                src={require('../../assets/images/popup/close-1.png')}
                onClick={handleClose}
                className="img-responsive close-click pull-right"
                alt="Image"
              /> */}
                    {success && (
                        <>
                            <div className="row popup-section">
                                {Object.keys(tickets).length == 0 && (
                                    <img
                                        src={require('../../assets/images/popup/close-1.png')}
                                        onClick={handleClose}
                                        className="img-responsive close-click pull-right"
                                        alt="Image"
                                    />
                                )}
                                <div className="confirmation-wrapper">
                                    <img
                                        src={require('../../assets/images/confirmation-1.png')}
                                        className="img-responsive"
                                        alt="Image"
                                    />
                                    <h4>
                                        Payment Completed
                                        <br/>
                                        <NumberFormat
                                            value={recPayment.charge_total}
                                            displayType={'text'}
                                            prefix="LKR "
                                            suffix="/="
                                            thousandSeparator={true}
                                        />
                                    </h4>
                                    <div className="row confirmation-row">
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <p>{recPayment.transaction_end_date}</p>
                                            <h5>{t('Payment Date')}</h5>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <p>{recPayment.id}</p>
                                            <h5> {t('Invoice No')}</h5>
                                        </div>
                                    </div>
                                    <div className="row confirmation-row">
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <p> {recPayment.txn_id}</p>
                                            <h5>{t('Transaction ID')}</h5>
                                        </div>
                                    </div>
                                    <div className="row confirmation-row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <h6>{t('Thanking you.')}</h6>
                                        </div>
                                    </div>
                                    <Link
                                        className="button-type-7 cursor-on"
                                        to="/my-transactions"
                                    >
                                        {t('Go to My Transactions')}
                                    </Link>
                                </div>
                            </div>
                        </>
                    )}

                    {recPayment.length == 0 && cardList.length > 0 && (
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="row top-wrapper">
                                {Object.keys(tickets).length == 0 && (
                                    <img
                                        src={require('../../assets/images/popup/close-1.png')}
                                        onClick={handleClose}
                                        className="img-responsive close-click pull-right"
                                        alt="Image"
                                    />
                                )}

                                <div className="row payment-method-wrapper payment-method-selector">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <h4>Payment Method</h4>
                                    </div>
                                    <label>
                                        <input
                                            type="radio"
                                            name="paymentMethod"
                                            checked={paymentMethod === "card_payment"}
                                            value="card_payment"
                                            onClick={(e) => handlePaymentTypeOnChange(e.target.value)}
                                        />

                                        <span>Credit/Debit</span>
                                        <img
                                            width="240"
                                            src={require('../../assets/images/payments/credit_debit_payments.png')}
                                            className="img-responsive"
                                            alt="Image"
                                        />
                                    </label>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="row payment-method-selector">
                                                {paymentMethod === 'card_payment' && cardList.map((card: any, i: number) => (
                                                    <div
                                                        key={i}
                                                        className={`col-xs-12 col-sm-12 col-md-12 col-lg-12 card-container ${
                                                            selectedCardId === card.id ? 'selected' : ''
                                                        }`}
                                                        onClick={() => setSelectedCardId(card.id)}
                                                    >
                                                        <span className="credit-card-label">
                                                          <span className="card-icon">
                                                            {card.card_type === 'VISA' && (
                                                                <>
                                                                    <img
                                                                        src={require('../../assets/images/visas.png')}
                                                                        className="img-responsive"
                                                                        alt="Visa"
                                                                    />
                                                                </>
                                                            )}
                                                              {card.card_type === 'MASTERCARD' && (
                                                                  <>
                                                                      <img
                                                                          src={require('../../assets/images/mastercardd.png')}
                                                                          className="img-responsive"
                                                                          alt="MasterCard"
                                                                      />
                                                                  </>
                                                              )}
                                                          </span>
                                                            <span className="card-details">
                                                                <b>&nbsp;{card.mask_card_no ? card.mask_card_no.slice(0, 4) : card.card_prefix}&nbsp;</b> XXXX XXXX <b>&nbsp;{card.mask_card_no ? card.mask_card_no.slice(-4) : card.card_postfix}&nbsp;</b>
                                                              </span>
                                                        </span>
                                                    </div>
                                                ))}
                                                <div
                                                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12 payment-method-selector">
                                                    <div
                                                        className={`col-xs-12 col-sm-12 col-md-12 col-lg-12 card-container ${
                                                            selectedCardId === 0 ? 'selected' : ''
                                                        }`}
                                                        onClick={() => {
                                                            setSelectedCardId(0)
                                                            setPaymentMethod('card_payment')
                                                        }}
                                                    >
                                                        <span className="credit-card-label">
                                                        Pay with New Card
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <label>
                                        <input
                                            type="radio"
                                            name="paymentMethod"
                                            checked={paymentMethod === "mobile_payment"}
                                            value="mobile_payment"
                                            onClick={(e) => handlePaymentTypeOnChange(e.target.value)}
                                        />

                                        <span>Mobile Payment</span>
                                        <img
                                            width="160"
                                            src={require('../../assets/images/payments/mobile_payments.png')}
                                            className="img-responsive"
                                            alt="Image"
                                        />
                                    </label>
                                </div>
                                {addNewCard &&
                                    <Modal
                                        className={`register-main-popup ${addNewCard ? 'in' : ''}`}
                                        show={addNewCard}
                                        onHide={() => setAddNewCard(false)}
                                        backdrop="true"
                                        centered
                                    >
                                        <BlockUi tag="div" blocking={blocking}>
                                            <div className="row row-clr group-wrapper card-detail-wrapper">
                                                <Box>
                                                    <div>
                                                        <Grid item xs={12} md={6}>
                                                            <AddCardForm user={user} selectCardType='visa' amount={amount} selectedCardId={selectedCardId} checked={checked} lotteryName={lotteryName} subscriptionRequest={subscriptionRequest} paymentMethod={paymentMethod} tickets={tickets} handleBuyTickets={handleBuyTickets} paymentGateway={paymentGateway} saveCard={saveCard} tokenization={tokenization}/>
                                                        </Grid>
                                                    </div>
                                                </Box>
                                            </div>
                                        </BlockUi>
                                    </Modal>
                                }
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <h4>Topup Account</h4>
                                    <p>Pay by WebXpay (Master/Visa)</p>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <img
                                        src={require('../../assets/images/payments-banner.jpg')}
                                        className="img-responsive"
                                        alt="Image"
                                    />
                                </div>
                            </div>

                            <div className="row mid-wrapper">
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                    <h4>{t('Amount')}</h4>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                    <input
                                        type="number"
                                        name="amount"
                                        id="amount"
                                        min={MINIMUM_TOPUP_AMOUNT}
                                        className="form-control"
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                        required
                                        title=""
                                    />
                                </div>
                            </div>

                            <div className="row lucky_number_wrapper">
                                <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 content-wrapper">
                                    <div className="row">
                                        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                            <h4>{t('Save Credit Card')}</h4>
                                        </div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                            <div
                                                className="pull-right"
                                                style={{marginTop: '20px'}}
                                            >
                                                <Switch
                                                    checked={checked}
                                                    onChange={(e) => handleCardSaving(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {save && (
                                <div className="row bottom-wrapper">
                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                        <h4>{t('Make this as a Recurring payment?')}</h4>
                                        <p>
                                            {t(
                                                'This option will topup the given amount automaticaly to your account'
                                            )}
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                <label>
                                                    <input
                                                        checked={tokenization == 1}
                                                        type="radio"
                                                        name="radio-1"
                                                        onClick={() => setTokenization(1)}
                                                    />
                                                    <span>{t('Yes')}</span>
                                                </label>
                                            </div>
                                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                <label>
                                                    <input
                                                        checked={tokenization == 0}
                                                        type="radio"
                                                        name="radio-1"
                                                        onClick={() => setTokenization(0)}
                                                    />
                                                    <span>{t('No')}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {total == 0 && (
                                <div className="row bottom-wrapper">
                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-5"></div>
                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                        {errMsg && (
                                            <p aria-live="assertive">
                                                <span className="has-error">{errMsg}</span>
                                            </p>
                                        )}
                                        <button
                                            type="button"
                                            className="button-type-8"
                                            onClick={(e) => createOrderEx(e)}
                                        >
                                            {t('Topup NOW')}
                                        </button>
                                        {error && (
                                            <Collapse in={error}>
                                                <Alert severity="error" onClose={() => setError(false)}>
                                                    {errorMsg}
                                                </Alert>
                                            </Collapse>
                                        )}
                                    </div>
                                </div>
                            )}

                            {extraChargeAlert && (
                                <div className="row bottom-wrapper">
                                    <div
                                        className="row row-clr error-wrapper red"
                                        data-aos="fade-down"
                                    >
                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 img-wrapper">
                                            <img
                                                src={require('../../assets/images/popup/exclamation-warning-round-red-icon.svg')}
                                                className="img-responsive"
                                                alt="Image"
                                            />
                                        </div>

                                        {extraChargeAlert &&
                                            <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 content-wrapper">
                                                <h6>
                                                    <strong>Warning:</strong> When there are fewer than LKR 2000.00 on
                                                    the card payments , LKR 7.00 additional charge will be added to
                                                    total as payment processing fee. Total is
                                                    Rs. {parseFloat(parseInt(amount)).toFixed(2)} +
                                                    Rs. 7.00 = Rs. {parseFloat(parseInt(amount) + 7.00).toFixed(2)}
                                                </h6>
                                            </div>}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {cardList.length == 0 && paymentGateway == 'genie' && (
                        <form
                            id="transForm"
                            action={`${GENIE_URL + '/tokenization?automation=yes'}`}
                            method="post"
                            acceptCharset="UTF-8"
                            encType="application/x-www-form-urlencoded"
                        >
                            <input
                                type="hidden"
                                id="currency"
                                name="currency"
                                value={CURRENCY}
                            />
                            <input
                                type="hidden"
                                id="cardSaveCard"
                                name="cardSaveCard"
                                value={saveCard}
                            />
                            <input
                                type="hidden"
                                id="invoiceNumber"
                                name="invoiceNumber"
                                value={order.id}
                            />
                            <input
                                type="hidden"
                                id="merchantDisplayName"
                                name="merchantDisplayName"
                                value={MERCHANT_NAME}
                            />
                            <input
                                type="hidden"
                                id="language"
                                name="language"
                                value="en-Us"
                            />
                            <input
                                type="hidden"
                                id="merchantPgIdentifier"
                                name="merchantPgIdentifier"
                                value={MERCHANT_PG_IDENTIFIER}
                            />
                            <input
                                type="hidden"
                                id="chargeTotal"
                                name="chargeTotal"
                                value={order.amount}
                            />

                            <input
                                type="hidden"
                                id="contextPath"
                                name="contextPath"
                                value="complete"
                            />

                            <input
                                type="hidden"
                                id="storeName"
                                name="storeName"
                                value={STORE_NAME}
                            />
                            <input
                                type="hidden"
                                id="transactionDateTime"
                                name="transactionDateTime"
                                value={order.created_at}
                            />

                            <input
                                type="hidden"
                                id="token"
                                name="token"
                                value={order.secret_code}
                            />
                            <input
                                type="hidden"
                                id="reference"
                                name="reference"
                                value={order.id}
                            />

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                {Object.keys(tickets).length == 0 && (
                                    <img
                                        src={require('../../assets/images/popup/close-1.png')}
                                        onClick={handleClose}
                                        className="img-responsive close-click pull-right"
                                        alt="Image"
                                    />
                                )}
                                <div className="row top-wrapper">
                                    <div className="row payment-method-wrapper payment-method-selector">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <h4>Payment Method</h4>
                                        </div>
                                        <label>
                                            <input
                                                type="radio"
                                                name="paymentMethod"
                                                checked={paymentMethod === "card_payment"}
                                                value="card_payment"
                                                onClick={(e) => handlePaymentTypeOnChange(e.target.value)}
                                            />

                                            <span>Credit/Debit</span>
                                            <img
                                                width="240"
                                                src={require('../../assets/images/payments/credit_debit_payments.png')}
                                                className="img-responsive"
                                                alt="Image"
                                            />
                                        </label>
                                        <div className="row payment-method-selector">
                                            <div
                                                className={`col-xs-12 col-sm-12 col-md-12 col-lg-12 card-container ${
                                                    selectedCardId === 0 ? 'selected' : ''
                                                }`}
                                                onClick={() => {
                                                    setSelectedCardId(0)
                                                    setPaymentMethod('card_payment')
                                                }}
                                            >
                                                        <span className="credit-card-label">
                                                        Pay with New Card
                                                        </span>
                                            </div>
                                        </div>
                                        <label>
                                            <input
                                                type="radio"
                                                name="paymentMethod"
                                                checked={paymentMethod === "mobile_payment"}
                                                value="mobile_payment"
                                                onClick={(e) => handlePaymentTypeOnChange(e.target.value)}
                                            />

                                            <span>Mobile Payment</span>
                                            <img
                                                width="160"
                                                src={require('../../assets/images/payments/mobile_payments.png')}
                                                className="img-responsive"
                                                alt="Image"
                                            />
                                        </label>
                                    </div>
                                    {addNewCard &&
                                        <Modal
                                            className={`register-main-popup ${addNewCard ? 'in' : ''}`}
                                            show={addNewCard}
                                            onHide={() => setAddNewCard(false)}
                                            backdrop="true"
                                            centered
                                        >
                                            <BlockUi tag="div" blocking={blocking}>
                                                <div className="row row-clr group-wrapper card-detail-wrapper">
                                                    <Box>
                                                        <div>
                                                            <Grid item xs={12} md={6}>
                                                                <AddCardForm user={user} selectCardType='visa' selectedCardId={selectedCardId}/>
                                                            </Grid>
                                                        </div>
                                                    </Box>
                                                </div>
                                            </BlockUi>
                                        </Modal>
                                    }
                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <h4>Topup Account</h4>
                                        <p>Pay by WebXpay (Master/Visa)</p>
                                    </div>
                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <img
                                            src={require('../../assets/images/payments-banner.jpg')}
                                            className="img-responsive"
                                            alt="Image"
                                        />
                                        {/* {Object.keys(tickets).length == 0 && (
                      <img
                        src={require('../../assets/images/popup/close-1.png')}
                        onClick={handleClose}
                        className="img-responsive close-click pull-right" style={{marginBottom:"73px"}}
                        alt="Image"
                      />
                    )} */}
                                    </div>
                                </div>

                                <div className="row mid-wrapper">
                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                        <h4>{t('Amount')}</h4>
                                    </div>
                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                        {/* {console.log("target amount", amount)} */}
                                        <input
                                            type="number"
                                            name="amount"
                                            id="amount"
                                            min={MINIMUM_TOPUP_AMOUNT}
                                            className="form-control"
                                            value={amount}
                                            onChange={handleAmountChange}
                                            required
                                            pattern=""
                                            title=""
                                        />
                                    </div>
                                </div>
                                <div className="row lucky_number_wrapper">
                                    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 content-wrapper">
                                        <div className="row">
                                            <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                                <h4>{t('Save Credit Card')}</h4>
                                            </div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                <div
                                                    className="pull-right"
                                                    style={{marginTop: '20px'}}
                                                >
                                                    <Switch
                                                        checked={checked}
                                                        onChange={(e) => handleCardSaving(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {save && (
                                    <div className="row bottom-wrapper">
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                            <h4>{t('Make this as a Recurring payment?')}</h4>
                                            <p>
                                                {t(
                                                    'This option will topup the given amount automaticaly to your account'
                                                )}
                                            </p>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <label>
                                                        <input
                                                            checked={tokenization == 1}
                                                            type="radio"
                                                            name="radio-1"
                                                            onClick={() => setTokenization(1)}
                                                        />
                                                        <span>{t('Yes')}</span>
                                                    </label>
                                                </div>
                                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <label>
                                                        <input
                                                            checked={tokenization == 0}
                                                            type="radio"
                                                            name="radio-1"
                                                            onClick={() => setTokenization(0)}
                                                        />
                                                        <span>{t('No')}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}


                                {total == 0 && (
                                    <div className="row bottom-wrapper">
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-5"></div>
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                            <button
                                                type="button"
                                                className="button-type-8"
                                                onClick={(e) => createOrderX(e)}
                                            >
                                                {t('Topup NOW')}
                                            </button>
                                            {error && (
                                                <Collapse in={error}>
                                                    <Alert
                                                        severity="error"
                                                        onClose={() => setError(false)}
                                                    >
                                                        {errorMsg}
                                                    </Alert>
                                                </Collapse>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </form>
                    )}

                    {cardList.length == 0 && paymentGateway == 'webxpay' && (
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            {Object.keys(tickets).length == 0 && (
                                <img
                                    src={require('../../assets/images/popup/close-1.png')}
                                    onClick={handleClose}
                                    className="img-responsive close-click pull-right"
                                    alt="Image"
                                />
                            )}
                            <div className="row top-wrapper">
                                <div className="row payment-method-wrapper payment-method-selector">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <h4>Payment Method</h4>
                                    </div>
                                    <label>
                                        <input
                                            type="radio"
                                            name="paymentMethod"
                                            checked={paymentMethod === "card_payment"}
                                            value="card_payment"
                                            onClick={(e) => handlePaymentTypeOnChange(e.target.value)}
                                        />

                                        <span>Credit/Debit</span>
                                        <img
                                            width="240"
                                            src={require('../../assets/images/payments/credit_debit_payments.png')}
                                            className="img-responsive"
                                            alt="Image"
                                        />
                                    </label>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="row payment-method-selector">
                                                <div
                                                    className={`col-xs-12 col-sm-12 col-md-12 col-lg-12 card-container ${
                                                        selectedCardId === 0 ? 'selected' : ''
                                                    }`}
                                                    onClick={() => {
                                                        setSelectedCardId(0)
                                                        setPaymentMethod('card_payment')
                                                    }}
                                                >
                                                        <span className="credit-card-label">
                                                        Pay with New Card
                                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <label>
                                        <input
                                            type="radio"
                                            name="paymentMethod"
                                            checked={paymentMethod === "mobile_payment"}
                                            value="mobile_payment"
                                            onClick={(e) => handlePaymentTypeOnChange(e.target.value)}
                                        />

                                        <span>Mobile Payment</span>
                                        <img
                                            width="160"
                                            src={require('../../assets/images/payments/mobile_payments.png')}
                                            className="img-responsive"
                                            alt="Image"
                                        />
                                    </label>
                                </div>
                                {addNewCard &&
                                    <Modal
                                        className={`register-main-popup ${addNewCard ? 'in' : ''}`}
                                        show={addNewCard}
                                        onHide={() => setAddNewCard(false)}
                                        backdrop="true"
                                        centered
                                    >
                                        <BlockUi tag="div" blocking={blocking}>
                                            <div className="row row-clr group-wrapper card-detail-wrapper">
                                                <Box>
                                                    <div>
                                                        <Grid item xs={12} md={6}>
                                                            <AddCardForm user={user} selectCardType='visa'/>
                                                        </Grid>
                                                    </div>
                                                </Box>
                                            </div>
                                        </BlockUi>
                                    </Modal>
                                }
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                    <h4>Topup Account</h4>
                                    <p>Pay by WebXpay (Master/Visa)</p>
                                </div>
                                <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                    <img
                                        src={require('../../assets/images/payments-banner.jpg')}
                                        className="img-responsive"
                                        alt="Image"
                                    />
                                    {/* {Object.keys(tickets).length == 0 && (
                      <img
                        src={require('../../assets/images/popup/close-1.png')}
                        onClick={handleClose}
                        className="img-responsive close-click pull-right" style={{marginBottom:"73px"}}
                        alt="Image"
                      />
                    )} */}
                                </div>
                            </div>

                            <div className="row mid-wrapper">
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                    <h4>{t('Amount')}</h4>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                    {/* {console.log("target amount", amount)} */}
                                    <input
                                        type="number"
                                        name="amount"
                                        id="amount"
                                        min={MINIMUM_TOPUP_AMOUNT}
                                        className="form-control"
                                        value={amount}
                                        onChange={handleAmountChange}
                                        required
                                        pattern=""
                                        title=""
                                    />
                                </div>
                            </div>
                            <div className="row lucky_number_wrapper">
                                <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 content-wrapper">
                                    <div className="row">
                                        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                            <h4>{t('Save Credit Card')}</h4>
                                        </div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                            <div className="pull-right" style={{marginTop: '20px'}}>
                                                <Switch
                                                    checked={checked}
                                                    onChange={(e) => handleCardSaving(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {save && (
                                <div className="row bottom-wrapper">
                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                                        <h4>{t('Make this as a Recurring payment?')}</h4>
                                        <p>
                                            {t(
                                                'This option will topup the given amount automaticaly to your account'
                                            )}
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                <label>
                                                    <input
                                                        checked={tokenization == 1}
                                                        type="radio"
                                                        name="radio-1"
                                                        onClick={() => setTokenization(1)}
                                                    />
                                                    <span>{t('Yes')}</span>
                                                </label>
                                            </div>
                                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                <label>
                                                    <input
                                                        checked={tokenization == 0}
                                                        type="radio"
                                                        name="radio-1"
                                                        onClick={() => setTokenization(0)}
                                                    />
                                                    <span>{t('No')}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {total == 0 && (
                                <div className="row bottom-wrapper">
                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-5"></div>
                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-7">
                                        <button
                                            type="button"
                                            className="button-type-8"
                                            onClick={(e) => createOrderX(e)}
                                        >
                                            {t('Topup NOW')}
                                        </button>
                                        {error && (
                                            <Collapse in={error}>
                                                <Alert severity="error" onClose={() => setError(false)}>
                                                    {errorMsg}
                                                </Alert>
                                            </Collapse>
                                        )}
                                    </div>
                                </div>
                            )}

                            {extraChargeAlert && (
                                <div className="row bottom-wrapper">
                                    <div
                                        className="row row-clr error-wrapper red"
                                        data-aos="fade-down"
                                    >
                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 img-wrapper">
                                            <img
                                                src={require('../../assets/images/popup/exclamation-warning-round-red-icon.svg')}
                                                className="img-responsive"
                                                alt="Image"
                                            />
                                        </div>

                                        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 content-wrapper">
                                            <h6>
                                                <strong>Warning:</strong> When there are fewer than LKR 2000.00 on
                                                the card payments , LKR 7.00 additional charge will be added to
                                                total as payment processing fee. Total is
                                                Rs. {parseFloat(parseInt(amount)).toFixed(2)} +
                                                Rs. 7.00 = Rs. {parseFloat(parseInt(amount) + 7.00).toFixed(2)}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </BlockUi>
        </>
    )
}

export default withTranslation()(TopupForm)
